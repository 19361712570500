<template>
  <div>
    <a
      href="#"
      @click.prevent="show_import_excel_modal = true"
      class="btn btn-primary btn-sm float-end"
      >Import Excel</a
    >
    <h4>Data Management & Analytics</h4>
    <div class="row" v-if="loaded">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Site Data</h5>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Registered Users</td>
                  <td>{{ site_data.users }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'users' }}">Details</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Newsletter Subscribers</td>
                  <td>{{ site_data.newsletter }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'newsletter' }}">Details</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Contact Requests</td>
                  <td>{{ site_data.contact_requests }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'contact' }}">Details</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Property Message Requests</td>
                  <td>{{ site_data.property_messages }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'message' }}">Details</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Mover Requests</td>
                  <td>{{ site_data.mover_requests }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'mover' }}">Details</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Painting & Cleaning Requests</td>
                  <td>{{ site_data.painting_cleaning_requests }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'painting' }}">Details</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Legal & SUrveying Requests</td>
                  <td>{{ site_data.legal_surveying_requests }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'legal' }}">Details</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Field Assistant Requests</td>
                  <td>{{ site_data.field_assistant_requests }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'field' }}">Details</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Relationship Manager Requests</td>
                  <td>{{ site_data.relationship_manager_requests }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'relationship' }}">Details</router-link>
                  </td>
                </tr>
                <!-- <tr>
                  <td colspan="2">All Site Data</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.site', params: { type: 'all' }}">Details</router-link>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>Uploaded Data</h5>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(file, i) in uploaded_files" :key="`file-${i}`">
                  <td>{{ file.name }}</td>
                  <td>{{ file.date }}</td>
                  <td>
                    <router-link :to="{ name: 'admin.data.excel', params: { id: file.id }}">Details</router-link>
                  </td>
                  <td>
                    <a href="#" @click.prevent="destroy(file.id)"><i class="lni lni-trash-can"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <loading v-if="! loaded" />

    <modal
      :show="show_import_excel_modal"
      @close="show_import_excel_modal = false"
    >
      <template slot="header">Import Excel Document</template>
      <form @submit.prevent="submit" v-if="! submitting">
        <div class="form-group">
          <label>Select Document</label>
          <input
            type="file"
            placeholder="Selet Document"
            class="form-control"
            ref="excel_document"
            required
          />
        </div>
        <div class="formgroup">
          <button class="btn btn-primary">Save</button>
        </div>
      </form>
      <loading v-if="submitting" />
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      show_import_excel_modal: false,
      loaded: false,
      site_data: [],
      uploaded_files: [],
      submitting: false
    };
  },

  methods: {
    fetch() {
      this.loaded = false

      this.$axios.get('/api/v1/admin/data').then(response => {
        this.site_data = response.data.site_data
        this.uploaded_files = response.data.uploaded_files
        this.loaded = true
      })
    },

    submit() {
      let data = new FormData();
      data.append("excel_file", this.$refs.excel_document.files[0]);
      this.submitting = true

      this.$axios.post("/api/v1/admin/data/excel", data).then((response) => {
        this.show_import_excel_modal = false;
        this.$router.push({ name: 'admin.data.excel', params: { id: response.data }})
      });
    },

    destroy(id) {
      this.loaded = false

      this.$axios.delete(`/api/v1/admin/data/excel/${id}`).then(() => {
        this.fetch()
      })
    }
  },
};
</script>
